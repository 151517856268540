import { Theme } from 'wikr-core-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectUIEffects } from 'redux/uiEffects/selectors';
import { setFontFamily } from 'redux/uiEffects/actions';

import { getFaviconElement } from 'helpers/utils';

export type ThemeName = 'default' | 'green' | 'fottur.com' | 'fottur.io' | 'fottur-stage';

export interface ThemeConfigScheme<T = Theme, P = ThemeName> {
    inlineStyle: object;
    themeStyle: T;
    faviconUrl: string;
    logoUrl: string;
    name: P;
}

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme<object, string>>) => {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState<ThemeConfigScheme<object, string>>();

    const { fontFamily } = useSelector(selectUIEffects);

    useEffect(() => {
        document.documentElement.style.setProperty('--global-font-family', fontFamily);
    }, [fontFamily]);

    if (!theme) {
        // const hostName = window.location.host;

        const theme = themeConfig?.['green'];
        // const theme = themeConfig?.[hostName] || themeConfig['default'];

        if (theme.name === 'green') {
            dispatch(setFontFamily('Inter'));
        }

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = theme.faviconUrl;

        setTheme(theme);
    }

    return (theme ?? themeConfig['default']) as ThemeConfigScheme;
};

export default useThemeConfiguration;
